<template>
    <div class="is-loading"></div>
</template>

<style scoped>
.is-loading {
    height: 40px;
    width: 100%;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
</style>
