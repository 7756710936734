import { defineAsyncComponent } from 'vue'
import LoadingComponent from '../components/LoadingComponent.vue'
import ErrorComponent from '../components/ErrorComponent.vue'

export const lazyLoadComponent = (asyncComponent) => {
    return defineAsyncComponent({
        loader: () => asyncComponent,
        delay: 400,
        timeout: 10000,
        loadingComponent: LoadingComponent,
        errorComponent: ErrorComponent,
    })
}
